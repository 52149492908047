/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react'
import { graphql, PageProps, navigate } from 'gatsby'
import { getSrc, getSrcSet } from 'gatsby-plugin-image'
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks'

import { addScrollToAnchor, getRedirectLanguage } from '../utils'
import Layout from '../containers/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import Inquiry from '../components/inquiry'
import { AllPressDataQuery } from '../../types/graphql-types'

interface PressPageProps extends PageProps {
  data: AllPressDataQuery
}
type PickType<T, K extends keyof T> = T[K]
type allContentfulPress = PickType<AllPressDataQuery, 'allContentfulPress'>
type allContentfulPressEdges = PickType<allContentfulPress, 'edges'>

type NodeProps = allContentfulPressEdges[number] & {
  popupActiveHandler: () => void
  popupInactiveHandler: () => void
  forceInactiveFlag: boolean
}
type YearNodes = {
  year: number
  nodes: PickType<NodeProps, 'node'>[]
}

const makeDisplayDate = (dateString: string) => {
  const date = new Date(dateString)

  return [date.getMonth() + 1, date.getDate(), date.getFullYear()].join('. ')
}

const NodePopupComponent: React.FC<NodeProps> = ({
  node,
  popupActiveHandler,
  popupInactiveHandler,
  forceInactiveFlag,
}) => {
  const { title, date, mediaFiles, comment } = node

  const [popupActiveState, setPopupActiveState] = React.useState(false)
  const popupClickHandler = () => {
    setPopupActiveState(true)
    popupActiveHandler()
  }
  const closeButtonClickHandler = () => {
    setPopupActiveState(false)
    popupInactiveHandler()
  }

  type ThumbnailData = {
    id: string
    imageSrc: string
    imageSrcSet: string
  }

  let thumbnails: ThumbnailData[] = []
  if (mediaFiles) {
    thumbnails = mediaFiles.flatMap(value => {
      if (value && value?.localFile) {
        const imageFileNode = value.localFile as FileNode
        const imageSrc = getSrc(imageFileNode)
        const imageSrcSet = getSrcSet(imageFileNode)
        if (imageSrc && imageSrcSet) {
          const thumbnail: ThumbnailData = {
            id: value.id,
            imageSrc,
            imageSrcSet,
          }

          return [thumbnail]
        }

        return []
      }

      return []
    })
  }

  return (
    <li
      key={node.id}
      className={`popup ${
        popupActiveState && !forceInactiveFlag ? 'active' : ''
      }`}
      role="presentation"
      onClick={popupActiveState ? undefined : popupClickHandler}
    >
      <div className="order">
        <div
          className="layer-close-btn"
          role="presentation"
          onClick={closeButtonClickHandler}
        >
          <img src="/images/layer-close.svg" alt="layer close button" />
        </div>
        <h3 className="date">{makeDisplayDate(date as string)}</h3>
        <p className="text">{title ?? ''}</p>
      </div>
      {thumbnails.length > 0
        ? thumbnails.map(thumbnail => (
            <div className="thumbnail" key={thumbnail.id}>
              <img
                src={thumbnail.imageSrc}
                srcSet={thumbnail.imageSrcSet}
                alt="thumbnail"
              />
            </div>
          ))
        : ''}
      <p className="comment">{comment?.comment ?? 'dummy'}</p>
    </li>
  )
}

const NodeLinkComponent: React.FC<NodeProps> = ({ node }) => {
  const { title, date, url } = node

  return (
    <li key={node.id} className="link">
      <a href={url ?? ''} target="_blank" rel="noreferrer">
        <div className="order">
          <div className="layer-close-btn">
            <img src="/images/layer-close.svg" alt="layer close button" />
          </div>
          <h3 className="date">{makeDisplayDate(date as string)}</h3>
          <p className="text">{title ?? ''}</p>
        </div>
      </a>
    </li>
  )
}

const NodeComponent: React.FC<NodeProps> = ({
  node,
  popupActiveHandler,
  popupInactiveHandler,
  forceInactiveFlag,
}) => {
  const { comment } = node

  return comment ? (
    <NodePopupComponent
      key={`${node.id}-popup`}
      node={node}
      popupActiveHandler={popupActiveHandler}
      popupInactiveHandler={popupInactiveHandler}
      forceInactiveFlag={forceInactiveFlag}
    />
  ) : (
    <NodeLinkComponent
      key={`${node.id}-link`}
      node={node}
      popupActiveHandler={popupActiveHandler}
      popupInactiveHandler={popupInactiveHandler}
      forceInactiveFlag={forceInactiveFlag}
    />
  )
}

const PressPage: React.FC<PressPageProps> = ({
  location,
  data,
  pageContext,
}) => {
  React.useEffect(() => {
    const urlLang = getRedirectLanguage()
    if (location.pathname.startsWith('/en/') && urlLang === '/') {
      const newPathname = location.pathname.replace('/en/', '/')
      void navigate(newPathname)
    }

    addScrollToAnchor()
  }, [location.pathname])
  const { language } = pageContext as { language: string }

  const [darkLayerActiveState, setDarkLayerActiveState] = React.useState(false)
  const [forceInactiveState, setForceInactiveState] = React.useState(false)

  const activeDarkLayer = () => {
    setForceInactiveState(false)
    setDarkLayerActiveState(true)
  }

  const inactiveDarkLayer = () => {
    setDarkLayerActiveState(false)
  }

  const darkLayerClickHandler = () => {
    setForceInactiveState(true)
    setDarkLayerActiveState(false)
  }

  const { edges } = data.allContentfulPress
  const yearNodes: YearNodes[] = []

  let currentIndex = 0

  edges.forEach(value => {
    const { node } = value
    const date = new Date(node.date as string)
    const year = date.getFullYear()
    if (yearNodes[currentIndex] === undefined) {
      yearNodes.push({ year, nodes: [node] })
    } else if (yearNodes[currentIndex].year !== year) {
      yearNodes.push({ year, nodes: [node] })
      currentIndex += 1
    } else {
      yearNodes[currentIndex].nodes.push(node)
    }
  })

  return (
    <Layout id="content" subtitle="Press" pageContext={pageContext}>
      <div
        className={`dark-layer ${darkLayerActiveState ? 'active' : ''}`}
        role="presentation"
        onClick={darkLayerClickHandler}
      />
      <section id="top">
        <Header location={location} pageContext={pageContext} />

        <div className="inner">
          <h1 className="title fadeup">Press</h1>
        </div>
      </section>

      <div className="content-wrap">
        <div className="inner-blur">
          <div className="left-content">
            <ul className="year">
              {yearNodes.map(value => (
                <li key={`#y-${value.year}`}>
                  <a href={`#y-${value.year}`}>{value.year}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="right-content">
            {yearNodes.map(yearNode => (
              <div id={`y-${yearNode.year}`} key={`y-${yearNode.year}-content`}>
                <h2>{yearNode.year}</h2>
                <ul className="headline">
                  {yearNode.nodes
                    .filter(node => node.node_locale.startsWith(language))
                    .map(node => (
                      <NodeComponent
                        key={`${node.id}-root`}
                        node={node}
                        popupActiveHandler={activeDarkLayer}
                        popupInactiveHandler={inactiveDarkLayer}
                        forceInactiveFlag={forceInactiveState}
                      />
                    ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Inquiry />
      <Footer location={location} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query allPressData {
    allContentfulPress(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          date
          url
          node_locale
          mediaFiles {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(height: 372)
              }
            }
          }
          comment {
            comment
          }
        }
      }
    }
  }
`

export default PressPage
